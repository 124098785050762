<!--
File: MaintenancePlan_single.vue
Description: form for showing the budget for each region with possibility to add/remove lines.
-->
<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-35">
            <h4 class="title">
              {{ $t('road_network.region') }}: {{ region }}, {{ $t('budget.year') }}: {{ year }}<!--,
              {{ $t('budget.budgeted_cost') }}: {{ budgetedCost | numFormat(2) }}-->
            </h4>
            <h5 class='title'>
              {{ $t('budget.total_cost') }}: {{ totalCost / showAmounts | numFormat(2) }}
            </h5>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <BaseDropdown :label="$t('translate.category')" v-model="selectedCategory" :items="workCategoriesList"
              :displayField="'description'" :valueField="'work_category_id'" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-10">
            <BaseDropdown :label="$t('budget.select_currency')" v-model="currency" :items="currencies"
              :displayField="'name'" :valueField="'id'" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-10">
            <BaseDropdown :label="$t('Show as')" v-model="showAmounts" :items="amounts" :displayField="'name'"
              :valueField="'id'" />
          </div>
          <div class="md-layout-item btn-row md-size-20">
            <md-button class="md-success" v-if="!isBudgetApproved" @click="showUnassignedWorksDlg">
              {{ $t('buttons.add') }}
            </md-button>
          </div>
          <div class="md-layout-item btn-row md-small-size-10">
            <md-button class="md-danger" v-if="!isBudgetApproved" @click="deleteSelected"
              :disabled="selectedItems.length == 0">
              {{ $t('buttons.delete') }}
            </md-button>
            <md-button class="md-success" @click="onSave"> {{ $t('buttons.save') }} </md-button>
          </div>
          <div class="md-layout-item btn-row md-small-size-10">
            <md-button class="md-success" @click="onClose"> {{ $t('buttons.close') }} </md-button>
          </div>
        </div>
        <md-table class="md-table" :value="budgetList" md-fixed-header>
          <md-table-row slot="md-table-row" slot-scope="{item}">
            <md-table-cell :md-label="$t('budget.category')">
              {{ item.work_category }}
            </md-table-cell>
            <md-table-cell :md-label="$t('budget.amount') + ', ' + showCurrency" md-numeric>
              {{ (showCurrency === "kgs" ? item.amount_kgs : item.amount_usd) / showAmounts | numFormat }}
            </md-table-cell>
            <md-table-cell md-label="Assigned amount" md-numeric>
              {{ assignedByBudgetCategory(item.budget_id) / showAmounts | numFormat }}
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-header>

      <md-card-content>
        <md-table class="paginated-table table-striped table-hover" :value="wpList"
          :md-selected-value.sync='selectedItems' md-fixed-header>
          <md-table-row slot="md-table-row" slot-scope="{item}"
            v-bind="!isBudgetApproved ? { 'md-selectable': 'multiple' } : {}">
            <md-table-cell style="min-width: 200px;" :md-label="$t('road_network.section')"
              md-sort-by="section_description">
              {{ item.section_description }}
            </md-table-cell>
            <md-table-cell :md-label="$t('label.hs_id')" md-sort-by="hs_id">
              {{ item.hs_id }}
            </md-table-cell>
            <md-table-cell style="min-width: 200px;" :md-label="$t('treatment.treatment_description')"
              md-sort-by="treatment_type_description">
              {{ item.treatment_type_description }}
            </md-table-cell>
            <md-table-cell :md-label="$t('condition.number_units')" md-sort-by="units" md-numeric>
              {{ item.units | numFormat(2) }}
            </md-table-cell>
            <md-table-cell :md-label="$t('label.units')" md-sort-by="unit_description">
              {{ item.unit_description }}
            </md-table-cell>
            <md-table-cell :md-label="$t('condition.priority_index')" md-sort-by="priority_index" md-numeric>
              {{ item.priority_index | numFormat(2) }}
            </md-table-cell>
            <md-table-cell :md-label="$t('condition.cost')" md-sort-by="cost" md-numeric>
              {{ item.cost / showAmounts | numFormat }}
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div class="md-layout-item md-small-size-100 md-size-50">
          <table>
            <tfoot>
              <tr>
                <th v-for=" item  in  footerTable " :key="item" class="md-table-head">
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">{{ item }}</div>
                  </div>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </md-card-content>
    </md-card>

    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
      <md-dialog-title v-if="unassignedWorksExist == true">{{ $t('budget.select_works') }}</md-dialog-title>
      <md-dialog-title v-else>{{ $t('budget.nothing_to_select') }}</md-dialog-title>
      <md-dialog-content>
        <md-table v-if="unassignedWorksExist == true" :value="unassignedWorks"
          class="paginated-table table-striped table-hover" @md-selected="onSelectdUnassignedWorks">
          <md-table-row slot="md-table-row" slot-scope="{item}" md-selectable="multiple" md-auto-select
            :md-disabled="item.financial_mln_kgs > (budgetLimit - totalUnassignedSelectedCost) && !selectedUnassignedWorks.includes(item)">
            <md-table-cell style="width:50%" :md-label="$t('road_network.section')">
              {{ item.section_description }}
            </md-table-cell>
            <md-table-cell style="min-width: 200px;" :md-label="$t('treatment.treatment_description')">
              {{ item.treatment_type_description }}
            </md-table-cell>
            <md-table-cell :md-label="$t('condition.priority_index')" md-numeric>
              {{ item.priority_index | numFormat(2) }}
            </md-table-cell>
            <md-table-cell :md-label="$t('condition.cost')" md-numeric>
              {{ item.cost / showAmounts | numFormat }}
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div v-else class='md-layout-item md-gutter'>
          <div class="card-icon">
            <md-icon>sentiment_very_dissatisfied</md-icon>
          </div>
          <label>{{ $t('budget.no_works_to_add') }}</label>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <h5 v-if="unassignedWorksExist" class='centered'>
          {{ $t('budget.limit') }} {{ budgetLimit(5) | numFormat(2) }} /
          {{ $t('budget.selected') }} {{ totalUnassignedSelectedCost | numFormat(2) }} <br>
          {{ $t('budget.maybeselected') }} {{ budgetLimit - totalUnassignedSelectedCost | numFormat(2) }}
        </h5>
        <md-button class="md-primary" @click="addUnassignedItems" v-if="unassignedWorksExist == true"
          :disabled="(selectedUnassignedWorks.length == 0) || (budgetLimit - totalUnassignedSelectedCost < 0)">
          {{ $t('buttons.add') }}
        </md-button>
        <md-button class="md-primary" @click="showDialog = false">{{ $t('buttons.close') }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import BaseDropdown from '@/pages/Dropdowns/BaseDropdown.vue'
import { numFormat } from "@/store/refdata/format_helpers"

export default {
  name: 'workprogrammes-list-for-a-region',
  data() {
    return {
      theBudget: { fk_region: 0, region: null, year: null, amount_kgs: 0, approved: true },

      region: null,
      year: null,
      showAmounts: 1000000,

      selectedWorklist: null,
      selectedRegion: null,
      selectedCategory: null,

      budget_id: null,
      exchange_rate: 89,
      currency: "kgs",
      selectable: "none",
      selectedItems: [],
      showDialog: false,
      //showConfirmationDlg: false,
      //confirmationDlgValue: false,
      selectedUnassignedWorks: [],

      amounts: [
        { id: 1, name: this.$t("soms") },
        { id: 1000, name: this.$t("Thousands") },
        { id: 1000000, name: this.$t("Millions") }
      ],
      showCurrency: 'kgs',
      currencies: [
        { id: 'kgs', name: 'KGS' },
        { id: 'usd', name: 'USD' }
      ],
    }
  },

  props: {
    //worklist: { default: null, type: Number },
    },

  components: {
    BaseDropdown
  },
  mounted() {
    const { workList, region, year } = this.$route.params
console.log('workList, region, years', workList, region, year)

    /*
    this.wpList[0].fk_budget
*/
    this.year = year
    //this.region = this.wpList[0].region_description
    this.selectedRegion = region  //this.wpList[0].fk_region
    this.selectedWorklist = workList    //this.wpList[0].work_list_id
    console.log('mounted', this.region, year)
    console.log('wpList', this.wpList[0])
    this.$store.dispatch('GET_WORK_CATEGORIES_LIST', true)
    this.loadTreatmentsList()
    },

  methods: {
    onClose() {
      this.$router.back()
      this.$router.replace({ path: '' })
    },

    loadTreatmentsList() {
        if (!this.selectedRegion || !this.selectedWorklist) {
          return
        }
        const params = {
          work_list_id: this.workList,
          budget_id: this.budget.budget_id,
        }
        this.$store.dispatch('LOAD_ALL_WORKS', params).then(() => {
        })
      },
  async onSave(){
      const res = await this.$store.dispatch('SAVE_ASSIGNED_TREATMENTS')
      console.log('onSave', res)
      //await Swal.fire('Saved', res + ' entries were added.', 'success')

    },

    onSelectdUnassignedWorks(items) {
      this.selectedUnassignedWorks = items
    },

    async deleteSelected() {
      if (this.selectedItems.length == 0) return;

      const { value: notes } = await Swal.fire({
        icon: 'warning',
        title: this.$t("Confirm deletion"),
        html: this.$t("You want to delete ") + this.selectedItems.length + this.$t(" items <br><br>Are you sure you want to do that?"),
        input: "text",
        inputPlaceholder: "Please give a remark for deletion",
        allowOutsideClick: false,
        showCancelButton: true,
      })

      // Exit if user selects cancel
      const keys = this.selectedItems.map((work) => work.treatment_id)
      this.selectedItems = []
      if (!notes) return

      this.showSpinner = true
      try {
        this.$store.commit('DELETE_ASSIGNED_TREATMENTS', keys)
        //await this.$store.dispatch('DELETE_ASSIGNED_TREATMENTS', { notes: 'Note from user', treatment_id: keys })
      } catch (error) {
        console.error('Error while deleting work list:', error)
      }
      Swal.fire('Deleted', `${keys.length} entries were deleted.`, 'success')
    },

    addUnassignedItems() {
      if (this.selectedUnassignedWorks.length == 0) return;

      // TODOS:- add checking whether total sum with addition is less than budgeted amount
      const keys = this.selectedUnassignedWorks.map(work => work.section_maintenance_plan_id)
      //this.$store.dispatch('ASSIGN_WP_BUDGET', { budget_id: this.budget_id, section_maintenance_plan_id: keys })

      //this.updateList()
      Swal.fire('Added', this.selectedUnassignedWorks.length + ' entries were added.', 'success')

      this.showDialog = false
      this.selectedUnassignedWorks = []
    },

    showUnassignedWorksDlg() {
      this.selectedUnassignedWorks = []
      if (!this.region && !this.year) return;

      const params = {
        work_list_id: this.selectedWorklist,
        region_id: this.selectedRegion,
        is_plan_assigned: 1,
        is_budget_assigned: 0,
        is_approved: 0,
      }
      this.$store.dispatch('LOAD_ALL_WORKS', params).then(() => {
        //     this.toggleSpinner(false)
      })
      this.showDialog = true
    },

    assignedByBudgetCategory(budget_id) {
      const res = this.$store.getters.assignedTreatmentsSummary(budget_id).totalSum
      console.log('assignedByBudgetCategory', budget_id, res)
      return res
    },

    budgetLimit(category) {
      const res = this.$store.getters.budgetsByCategory(this.selectedRegion, this.year, budget_id)
      console.log('budgetLimit', budget_id, res)
      return res
    },

 },
  computed: {
    ...mapState({
      wpList: (state) => state.TitleList.treatmentsList,   //assignedTreatments,
      unassignedWorks: (state) => state.TitleList.treatmentsList,
      workCategoriesList: (state) => state.Dropdowns.work_categories,
    }),
    ...mapGetters(['assignedTreatmentsSummary', 'budgetsByRegionAndYear', 'budgetsByCategory']),
    budgetList() {
      const res = this.$store.getters.budgetsByRegionAndYear(this.selectedRegion, this.year)
      console.log(res)
      return res
    },

    isBudgetApproved() {
      return false
    },
    unassignedWorksExist() {
      return this.unassignedWorks.length > 0
    },
    currency_str() {
      return ' ' + this.$t('budget.mln') + this.currency
    },
    budgetedCost() {
      return (!this.theBudget) ? 0 : this.theBudget.amount_kgs
    },
    totalCost() {
      return this.assignedTreatmentsSummary.totalSum
    },
    totalSelectedCost() {
      return this.selectedItems.length > 0 ? this.selectedItems.reduce(
        (accumulator, currentValue) => accumulator + currentValue.cost, 0) : 0
    },
    totalUnassignedSelectedCost() {
      return this.selectedUnassignedWorks.length > 0 ? this.selectedUnassignedWorks.reduce(
        (accumulator, currentValue) => accumulator + currentValue.cost, 0,) : 0
    },
    alreadySelected(item) {
      console.log(this.selectedUnassignedWorks.includes(item))
      return this.selectedUnassignedWorks.includes(item)
    },
    itemSelectable(item) {
      console.log(this.selectedUnassignedWorks.includes(item))
      //        return (item.section_maintenance_plan_id % 2) == 0
      return true//(item.section_description_en.includes('M'))
    },
    summaryInfo() {
      console.log(this.$store.getters.assignedTreatmentsSummary())
      return this.$store.getters.assignedTreatmentsSummary()
    },
    footerTable() {
      return [
        this.$t('label.total'),
        `${this.$t('budget.works_count')}: ${numFormat(this.summaryInfo.totalRecords, 0)}`,
        `${this.$t('road_network.length_km')}: ${numFormat(this.summaryInfo.totalLength / 1000, 3)}`,
        `${this.$t('budget.total_cost')}: ${numFormat(this.summaryInfo.totalSum / this.showAmounts, 2)}`,
      ]
    },
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.md-table-cell-container {
  padding: 10px 10px 10px 0px !important;
}

.paginated-table table>tbody>tr>td .paginated-table table>tbody>tr>th {
  padding: 10px 10px 10px 0px !important;
  max-width: 250px;
  width: 150px;
}

.md-table-cell-selection {
  display: inline;
  justify-content: center;
  overflow: visible;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
</style>